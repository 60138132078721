import axios from "axios";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";

const currentYear = new Date().getFullYear();

const CreatePost = () => {
  const [err, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedSemester, setSelectedSemester] = useState("");
  const [submittedData, setSubmittedData] = useState({});
  const [formData, setFormData] = useState({
    code: "",
    name: "",
    tutor: "",
    type: "",
    time: "",
    hours: "",
    img: "",
    price: "",
    description: "",
  });

  const labelTranslations = {
    code: "课程代码",
    name: "课程名称",
    tutor: "老师",
    type: "课程类别",
    time: "年份与学期",
    hours: "总时长",
    img: "图片链接地址",
    price: "价格",
    description: "描述",
  };

  const typeOptions = {
    assignment: "作业班",
    weekly: "平时班",
    open: "公开课",
    final: "期末班",
  };

  // ***SWITCH TESTING MODE & PRODUCTION MODE***
  // const local_url = "http://localhost:1235";
  const remote_url =
    "https://victor-edu-website-backend-a3f8287f423c.herokuapp.com";

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { year, semester, ...rest } = formData;
    const combinedTime = `${year}${semester}`;
    const finalData = { ...rest, time: combinedTime };
    // Now send finalData to the backend

    try {
      await axios.post(`${remote_url}/api/posts/`, finalData, {
        withCredentials: true,
      });
      setSubmittedData(finalData);
      console.log("\nSubmitted Data:\n", submittedData);
      setShowModal(true);
      setFormData({
        code: "",
        name: "",
        tutor: "",
        type: "",
        time: "",
        hours: "",
        img: "",
        price: "",
        description: "",
      });
    } catch (err) {
      setError(err.response.data);
    }
  };

  const handleSelectType = (typeKey) => {
    // Update form data with the English value
    setFormData({ ...formData, type: typeKey });
    // Update the selected type state with the Chinese label for display
    setSelectedType(typeOptions[typeKey]);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectYear = (year) => {
    setSelectedYear(year); // Update the state
    setFormData({ ...formData, year }); // Update formData if needed
  };

  const handleSelectSemester = (semester) => {
    setSelectedSemester(semester); // Update the state
    setFormData({ ...formData, semester }); // Update formData if needed
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit} className="mt-3 mb-5">
        <h1>创建海报</h1>
        <Row>
          {Object.entries(formData).map(([key, value]) => {
            if (
              key === "time" ||
              key === "year" ||
              key === "semester" ||
              key === "type"
            ) {
              return null; // These are handled separately
            }
            return (
              <Col md={6} sm={12} key={key}>
                <Form.Group controlId={`form${key}`} className="mt-3">
                  <Form.Label>{labelTranslations[key]}</Form.Label>
                  <Form.Control
                    required={key !== "img"}
                    type={key === "url" ? "url" : "text"}
                    placeholder={`Enter ${key}`}
                    name={key}
                    value={value}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            );
          })}
          <Col md={3} sm={6} className="mt-3">
            <Form.Label>类型</Form.Label>
            <DropdownButton
              title={selectedType || "--选择类型--"}
              onSelect={handleSelectType}
              variant="outline-secondary"
              id="dropdown-type-selection"
            >
              {Object.entries(typeOptions).map(([key, label]) => (
                <Dropdown.Item key={key} eventKey={key}>
                  {label}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>

          <Col md={3} sm={6} className="mt-3">
            <Form.Label>年份</Form.Label>
            <DropdownButton
              title={selectedYear || "--选择年份--"}
              onSelect={handleSelectYear}
              variant="outline-secondary"
            >
              {[currentYear - 1, currentYear, currentYear + 1].map((year) => (
                <Dropdown.Item eventKey={year} key={year}>
                  {year}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>

          <Col md={3} sm={6} className="mt-3">
            <Form.Label>学期</Form.Label>
            <DropdownButton
              title={selectedSemester || "--选择学期--"} // Display the selected semester
              onSelect={handleSelectSemester}
              variant="outline-secondary"
            >
              {[
                "sem1",
                "sem2",
                "summer",
                "winter",
                "term1",
                "term2",
                "term3",
              ].map((term) => (
                <Dropdown.Item eventKey={term} key={term}>
                  {term}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
        </Row>

        {err && <p className="err">{err.message}</p>}

        <Button variant="primary" type="submit" className="mt-4">
          提交
        </Button>
      </Form>

      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "darkgreen" }}>
              海报创建成功!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.entries(submittedData).map(([key, value]) => {
              return <p key={key}>{`${labelTranslations[key]}: ${value}\n`}</p>;
            })}
            <p style={{ color: "darkgreen" }}>以上信息已被成功存入数据库。</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              关闭
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default CreatePost;
