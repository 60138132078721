import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AdditionalContentExample } from "../../components/alert/Alert";

const discussionContent = {
  h: "施工中~",
  p0: "此階段尚未開放此功能，工作人員正在拼命趕工中~! 盡請期待!",
  p1: "",
};

function Discussion() {
  return <AdditionalContentExample props={discussionContent} />;
}

export default Discussion;
