import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Item = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [data, setData] = useState([]);

  // ***SWITCH TESTING MODE & PRODUCTION MODE***
  // const local_url = "http://localhost:1235";
  const remote_url =
    "https://victor-edu-website-backend-a3f8287f423c.herokuapp.com";

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const response = await axios.get(`${remote_url}/api/posts/` + path);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching post data: ", error);
      }
    };

    fetchPostData();
  }, [path]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const renderType = (type) => {
    if (type === "open") {
      return "公开课";
    } else if (type === "final") {
      return "期末班";
    } else if (type === "weekly") {
      return "平时班";
    } else if (type === "assignment") {
      return "作业班";
    }
    return type;
  };

  function handleClick() {
    navigate("/purchase");
  }

  return (
    <Container className="outlet">
      <Row className="align-items-center my-5">
        <Col xs={12} md={6}>
          <Image src={data.img} alt={data.name} thumbnail fluid />
        </Col>
        <Col xs={12} md={6}>
          <h2 style={{ textTransform: "uppercase" }}>
            {data.code} {data.name}
          </h2>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>类别:</strong> {renderType(data.type)}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>讲师:</strong> {data.tutor}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>总时长:</strong> {data.hours}个小时
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>价格:</strong> AU$ {data.price}
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>描述:</strong> {data.description}
            </ListGroup.Item>
          </ListGroup>
          <Button
            variant="success"
            size="lg"
            block
            className="mt-4"
            onClick={handleClick}
          >
            购买
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Item;
