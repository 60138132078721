import React, { useEffect, useState } from "react";
import "./Course.css";
import Gallery from "../../components/gallery/Gallery";
import { Container } from "react-bootstrap";
import axios from "axios";

// const images = [
//   { src: "/images/comp90007-it.png", alt: "1" },
//   { src: "/images/comp90038-alog.png", alt: "2" },
//   { src: "/images/comp90041-java.png", alt: "3" },
//   { src: "/images/comp90049-iml.png", alt: "4" },
//   { src: "/images/comp90050-adbs.png", alt: "5" },
//   { src: "/images/info90002-db.png", alt: "6" },
//   { src: "/images/swen90016-spm.png", alt: "7" },
//   { src: "https://placehold.co/1280x720?text=08", alt: "8" },
//   { src: "https://placehold.co/1280x720?text=09", alt: "9" },
// ];

function Course() {
  const [posts, setPosts] = useState([]);
  // ***SWITCH TESTING MODE & PRODUCTION MODE***
  // const local_url = "http://localhost:1235";
  const remote_url =
    "https://victor-edu-website-backend-a3f8287f423c.herokuapp.com";

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${remote_url}/api/posts/`);
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchPosts();
  }, []);

  const assignmentType = posts.filter((post) => post.type === "assignment");
  const openType = posts.filter((post) => post.type === "open");
  const weeklyType = posts.filter((post) => post.type === "weekly");
  const finalType = posts.filter((post) => post.type === "final");

  return (
    <Container className="outlet">
      <hr style={{ color: "white", height: "1rem" }} />
      {openType.length > 0 && <Gallery title="公开课" images={openType} />}
      {weeklyType.length > 0 && <Gallery title="平时班" images={weeklyType} />}
      {assignmentType.length > 0 && (
        <Gallery title="作业班" images={assignmentType} />
      )}
      {finalType.length > 0 && <Gallery title="期末班" images={finalType} />}
    </Container>
  );
}

export default Course;
