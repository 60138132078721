import "./App.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./pages/home/Home";
import BasicExample from "./components/navbar/Navbar";
import About from "./pages/about/About";
import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
import Course from "./pages/course/Course";
import Profile from "./pages/profile/Profile";
import Discussion from "./pages/discussion/Discussion";
import Cart from "./pages/cart/Cart";
import Item from "./pages/item/Item";
import Single from "./pages/single/Single";
import Footer from "./components/footer/Footer";
import Tutor from "./pages/tutor/Tutor";
import { AdminCreatePost } from "./pages/admin/Admin";
import Purchase from "./pages/purchase/Purchase";
import ProfileEdit from "./pages/profileEdit/ProfileEdit";
import CreateCourse from "./pages/createCourse/CreateCourse";
import CreatePost from "./pages/createPost/CreatePost";

const Layout = () => {
  return (
    <>
      <BasicExample />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/course",
        element: <Course />,
      },
      {
        path: "/course/:slug",
        element: <Single />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/tutor",
        element: <Tutor />,
      },
      {
        path: "/user/:slug",
        element: <Profile />,
      },
      {
        path: "/user/edit/:slug",
        element: <ProfileEdit />,
      },
      {
        path: "/discussion",
        element: <Discussion />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/item/:slug",
        element: <Item />,
      },
      {
        path: "/purchase",
        element: <Purchase />,
      },
      {
        path: "/admin/create-post",
        element: <CreatePost />,
      },
      {
        path: "/admin/create-course",
        element: <CreateCourse />,
      },
    ],
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
