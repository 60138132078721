import React from "react";
import "./Purchase.css";
import { AdditionalContentExample } from "../../components/alert/Alert";

const purchaseContent = {
  h: "您好，请联系客服进行课程购买。",
  p0: "您可以通过微信客服与我们的团队取得联系。微信二维码可以在页面的最下方以及关于公司页面找到。感谢您的合作！",
  p1: "",
};

function Purchase() {
  return <AdditionalContentExample props={purchaseContent} />;
}

export default Purchase;
