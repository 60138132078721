import React, { useEffect, useState } from "react";
import ControlledCarousel from "../../components/carousel/Carousel";
import Gallery from "../../components/gallery/Gallery";
import Feature from "../../components/feature/Feature";
import Affiliation from "../../components/affiliation/Affiliation";
import axios from "axios";

function Home() {
  const [posts, setPosts] = useState([]);
  // ***SWITCH TESTING MODE & PRODUCTION MODE***
  // const local_url = "http://localhost:1235";
  const remote_url =
    "https://victor-edu-website-backend-a3f8287f423c.herokuapp.com";

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${remote_url}/api/posts/`);
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchPosts();
  }, []);

  const assignmentType = posts.filter((post) => post.type === "assignment");
  const openType = posts.filter((post) => post.type === "open");
  const weeklyType = posts.filter((post) => post.type === "weekly");
  const finalType = posts.filter((post) => post.type === "final");

  // const renderGallery = (type) => {
  //   const filteredPosts = posts.filter(post => post.type === type);
  //   return <Gallery title={type} images={filteredPosts} />;
  // };

  return (
    <div className="outlet">
      <ControlledCarousel />
      <Affiliation />
      <Feature />
      {openType.length > 0 && <Gallery title="公开课" images={openType} />}
      {weeklyType.length > 0 && <Gallery title="平时班" images={weeklyType} />}
      {assignmentType.length > 0 && (
        <Gallery title="作业班" images={assignmentType} />
      )}
      {finalType.length > 0 && <Gallery title="期末班" images={finalType} />}
      {/* <Gallery title="公开课" images={openType} />
      <Gallery title="平时班" images={weeklyType} />
      <Gallery title="作业班" images={assignmentType} />
      <Gallery title="期末班" images={finalType} /> */}
    </div>
  );
}

export default Home;
