import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Carousel.css";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const slides = [
    {
      src: "https://images.unsplash.com/photo-1595434971780-79d5c20c5090?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      label: "Oweek",
      description: "",
      alt: "melbourne-city-view",
    },
    {
      src: "https://images.unsplash.com/photo-1573040636871-5e40de475ba7?q=80&w=3132&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      label: "选课指南",
      description: "",
      alt: "unimelb-logo",
    },
    {
      src: "https://images.unsplash.com/photo-1609036567523-c804ca6b22e9?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      label: "免费公开课",
      description: "",
      alt: "unimelb-building",
    },
    {
      src: "/images/oweek-boy.jpg",
      label: "Oweek",
      description: "",
      alt: "oweek-boy",
    },
    {
      src: "/images/oweek-girl.jpg",
      label: "Oweek",
      description: "",
      alt: "oweek-girl",
    },
  ];

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="hero-carousel-container">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {slides.map((slide, idx) => (
          <Carousel.Item key={idx}>
            <div className="ratio-16x9">
              <img className="d-block w-100" src={slide.src} alt={slide.alt} />
            </div>
            {/* <Carousel.Caption>
            <h1 style={{ color: "white" }}>{slide.label}</h1>
            <p>{slide.description}</p>
          </Carousel.Caption> */}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default ControlledCarousel;
