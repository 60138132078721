import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import "./Tutor.css";

const tutors = [
  {
    name: "HAO 学长",
    description: [
      "墨大Master - Information Technology毕业",
      "个人所学科目成绩均分H1 (90+)",
      "本科上海交大毕业",
      "拥有3年在墨尔本工作经验，职位是高级全栈工程师",
      "教授科目: COMP90007 - IT",
    ],
  },
  {
    name: "OG 学长",
    description: [
      "墨大Bachelor - CS 毕业",
      "个人所学科目成绩均分H1，专业科目90+",
      "拥有6年以上大班授课讲师经验",
      "带过的墨大学生9成拿H1，且超过10位拿到Dean’s List",
      "有5年工作经验擅长云端架构，后端设计",
      "目前在墨尔本大厂担任后端工程师",
      "教授科目: COMP90038 - ALGO",
    ],
  },
  {
    name: "Amy 学姐",
    description: [
      "墨尔本大学 master of information technology 毕业",
      "均分H1， distinction毕业",
      "澳洲本地两年工作经验",
      "善于通过举例将抽象的理论具体化，从而帮助学生理解知识点，然后通过习题，巩固知识点教授科目：INFO90002-DB",
    ],
  },
  // ... more tutor objects
];

const founders = [
  {
    name: "NOVA",
    description: [
      "墨大Master -Information Technology毕业:个人所学科目成绩均分H1",
      "拥有10年教学经验，被同学称为高分制造机。善于以念理解为主，搭配例题练习，地毯式教学抓住所有知识细节，帮助同学取得高分成绩;",
      "连续多次带出全系最高分以及H1同学",
      "5年开发经验，目前是软件工程师",
      "教授科目:COMP90050-ADBS、SWEN90016-SPM、COMP90041-Java",
    ],
  },
  {
    name: "Kelvin",
    description: [
      "机器学习博士毕业，獲得學院論文獎",
      "曾在國際機器學習大會 (ICML) 發表論文，並在歐洲機器學習大會 (ECML) 获得最佳论文奖",
      "學士以大学奖章 (全系第一) 和一等荣誉畢業",
      "多年业界工作经验，现任职于 MBB 的 Lead Data Scientist",
      "于 Usyd 带过7年 Computer Science & Data Science 的课，获得最佳Tutor Award",
    ],
  },
];

const Tutor = () => {
  return (
    <Container className="outlet">
      <h2 className="group-heading">Co-Founder</h2>
      <Row className="g-4">
        {founders.map((founder, idx) => (
          <Col key={idx} xs={12}>
            <Card>
              <Card.Body>
                <Card.Title className="tutor-name">{founder.name}</Card.Title>
                <ul>
                  {founder.description.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
                {/* You can add more Card.Text or other components here */}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <hr style={{ color: "grey" }} />
      <h2 className="group-heading">IT 团队</h2>
      <Row className="g-4">
        {tutors.map((tutor, idx) => (
          <Col key={idx} xs={12}>
            <Card>
              <Card.Body>
                <Card.Title className="tutor-name">{tutor.name}</Card.Title>
                <ul>
                  {tutor.description.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
                {/* You can add more Card.Text or other components here */}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <hr style={{ color: "grey" }} />
      <h2 className="group-heading">Commerce 团队</h2>
      <p>正在加载中...</p>
    </Container>
  );
};

export default Tutor;
