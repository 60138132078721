import React, { useContext } from "react";
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/user/edit/${currentUser.slug}`);
  };

  const imageStyle = {
    maxHeight: "200px",
    maxWidth: "100%",
    objectFit: "cover",
    borderRadius: "0.25rem",
  };

  const defaultAvatar =
    "https://media.istockphoto.com/id/1450268558/photo/summer-selfie.webp?b=1&s=170667a&w=0&k=20&c=Vipvrhj292OQRhFkUTIqKnbtMlSn7EWlIbZRzWE66TU=";

  return (
    <Container className="mt-5">
      <Row>
        <Col lg={4} md={12} className="mb-3 mb-lg-0">
          <Card.Img
            style={imageStyle}
            src={currentUser.avatar || defaultAvatar}
            alt="Profile image"
          />
        </Col>
        <Col lg={8} md={12}>
          <Card className="flex-fill">
            <Card.Body>
              <Card.Title style={{ fontSize: "3em" }}>
                {currentUser.nickname}
              </Card.Title>
              <Card.Subtitle
                className="mb-2 text-muted"
                style={{ fontWeight: "200" }}
              >
                {currentUser.study}
              </Card.Subtitle>
              <Card.Text>{currentUser.school}</Card.Text>
            </Card.Body>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <span style={{ fontWeight: "bold" }}>电子邮箱: </span>
                {currentUser.email}
              </ListGroup.Item>
              <ListGroup.Item>
                <span style={{ fontWeight: "bold" }}>手机号码: </span>{" "}
                {currentUser.phone}
              </ListGroup.Item>
              <ListGroup.Item>
                <span style={{ fontWeight: "bold" }}>学校: </span>{" "}
                {currentUser.school}
              </ListGroup.Item>
              <ListGroup.Item>
                <span style={{ fontWeight: "bold" }}>专业: </span>{" "}
                {currentUser.study}
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Button
            variant="primary"
            type="submit"
            className="mt-3 mb-5"
            style={{ marginLeft: "8px" }}
            onClick={handleEditClick}
          >
            编辑信息
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
