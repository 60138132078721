import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "./Navbar.css";

function BasicExample() {
  const { currentUser, logout } = useContext(AuthContext);

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src="/images/victor-logo.svg"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Victor Education
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/course">课程列表</Nav.Link>
            <Nav.Link href="/discussion">论坛</Nav.Link>

            <NavDropdown title="关于" id="basic-nav-dropdown">
              <NavDropdown.Item href="/about">公司</NavDropdown.Item>
              <NavDropdown.Item href="/tutor">团队</NavDropdown.Item>
            </NavDropdown>

            {currentUser ? (
              <NavDropdown title={currentUser.nickname} id="basic-nav-dropdown">
                <NavDropdown.Item href={`/user/${currentUser.nickname}`}>
                  个人信息
                </NavDropdown.Item>

                {currentUser.admin ? (
                  <>
                    <NavDropdown.Item href="/admin/create-course">
                      创建课程
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/admin/create-post">
                      创建海报
                    </NavDropdown.Item>
                  </>
                ) : (
                  <NavDropdown.Item href="/cart">已购课程</NavDropdown.Item>
                )}
                <NavDropdown.Divider />
                <NavDropdown.Item href="/" onClick={logout}>
                  登出
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link href="/login" className="nav-login-link">
                登录
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
