import React, { useState, useContext } from "react";
import { schools, studies } from "../register/Register";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import axios from "axios";

const ProfileEdit = () => {
  // ***SWITCH TESTING MODE & PRODUCTION MODE***
  // const local_url = "http://localhost:1235";
  const remote_url =
    "https://victor-edu-website-backend-a3f8287f423c.herokuapp.com";

  const { currentUser, logout } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    ...currentUser,
    school: currentUser.school || "The University of Melbourne",
    study: currentUser.study || "Master of Information Technology",
  });

  const [err, setError] = useState(null);
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(`/user/${currentUser.slug}`);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log("Form submitted", formData);
      await axios.put(`${remote_url}/api/users/${currentUser.slug}`, formData, {
        withCredentials: true,
      });
      logout();
      navigate("/login");
    } catch (err) {
      setError(err.response.data);
    }
  };

  return (
    <Container className="outlet" style={{ paddingTop: "1.5rem" }}>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <h2 className="mb-4">用户信息更新</h2>
          <Alert key="warning" variant="warning">
            请问注意：您一旦提交更新后，页面将会跳转到登录页面重新登录。
          </Alert>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-4">
              <Form.Label>昵称</Form.Label>
              <Form.Control
                type="text"
                name="nickname"
                required
                value={formData.nickname}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>电子邮箱</Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>手机号码</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>学校</Form.Label>
              <Form.Select
                name="school"
                required
                value={formData.school}
                onChange={handleInputChange}
              >
                {schools.map((school) => (
                  <option key={school} value={school}>
                    {school}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>专业</Form.Label>
              <Form.Select
                name="study"
                required
                value={formData.study}
                onChange={handleInputChange}
              >
                {studies.map((study) => (
                  <option key={study} value={study}>
                    {study}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>新密码</Form.Label>
              <Form.Control
                type="password"
                name="password"
                required
                value={formData.password}
                onChange={handleInputChange}
              />
            </Form.Group>

            {err && <p className="err">{err.message}</p>}

            <Button
              variant="primary"
              type="submit"
              style={{ marginRight: "8px" }}
            >
              提交更新
            </Button>
            <Button
              variant="outline-secondary"
              type="submit"
              onClick={handleCancelClick}
            >
              取消
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileEdit;

// To use this component, you need to pass the existing user data as a prop like this:
// <UserProfileEdit existingUserData={theExistingUserData} />
