import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
// import Table from "../../components/table/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Link } from "react-router-dom";

const Cart = () => {
  const { currentUser } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [courses, setCourses] = useState([]); // Courses should be an array
  const [filteredData, setFilteredData] = useState([]); // FilteredData should be an array

  // ***SWITCH TESTING MODE & PRODUCTION MODE***
  // const local_url = "http://localhost:1235";
  const remote_url =
    "https://victor-edu-website-backend-a3f8287f423c.herokuapp.com";

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const subscriptionsData = await Promise.all(
          currentUser.subscriptions.map((slug) =>
            axios.get(`${remote_url}/api/courses/${slug}`, {
              withCredentials: true,
            })
          )
        );
        setCourses(subscriptionsData.map((response) => response.data));
        setFilteredData(subscriptionsData.map((response) => response.data)); // Initialize filteredData
      } catch (error) {
        console.error("Error fetching subscription data", error);
      }
    };

    if (currentUser?.subscriptions) {
      fetchSubscriptions();
    }
  }, [currentUser]);

  const handleSearch = (event) => {
    event.preventDefault();
    const filtered = courses.filter((course) =>
      course.code.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <Container className="mt-3">
      <Row className="justify-content-md-center my-4">
        <Col md={6}>
          <Form inline="true" onSubmit={handleSearch} className="d-flex">
            <FormControl
              type="text"
              placeholder="输入课程代码..."
              className="me-2 flex-grow-1"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button type="submit" variant="outline-success">
              <i className="fa fa-search"></i>
            </Button>
          </Form>
        </Col>
      </Row>

      <Row style={{ marginTop: "3rem" }}>
        <Col>
          <table className="table">
            <thead>
              <tr>
                <th>课程</th>
                <th>时间</th>
                <th>类别</th>
                <th>讲师</th>
                <th>播放</th>
              </tr>
            </thead>
            <tbody>
              {(searchTerm ? filteredData : courses).map((course, index) => (
                <tr key={index}>
                  <td style={{ textTransform: "uppercase" }}>
                    {course.code} {course.name}
                  </td>
                  <td>{course.time}</td>
                  <td>{course.type}</td>
                  <td>{course.tutor}</td>
                  <td>
                    <Link to={`/course/${course.slug}`}>&#9658;</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default Cart;
