import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const About = () => {
  const qrCodeStyle = {
    width: "150px",
    height: "150px",
  };

  return (
    <Container className="outlet">
      <Row className="my-5">
        <Col>
          <h2>我们的故事</h2>
          <p>我们是Victor Education ，成立于2023年，在墨尔本正式注册，</p>
          <p>
            我们的使命是通过最专业的教师和最新的学习资料，提供多样化和有效率的学习，协助学生们实现学习目标，激发潜力。使学生对于特定学科能有更加全面扎实的学习，且获得更高的成绩。在2024sm1我们将会先开设针对
            Master of IT
            的一些辅导课程，之后也会陆续新增其他专业的其他课程。我们的Tutor团队都是Unimelb
            H1毕业且已在墨尔本工作的学长姐，因此不管是刚入学的新生或是正准备开始找工作实习的准毕业生，我们都能准确的抓住大家的需求，给予建议与方向，甚至是内推机会。
          </p>
          <p>希望各位同学在Victor 的帮助下，学习更顺利且快乐~!</p>
          <br />
          <h2>Contact Us:</h2>
          <p>Tel: (04) 35889008</p>
          <p>Email: victor@victor-edu.com.au</p>
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} md={4}>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Card.Title className="card-title-top">小红书</Card.Title>
              <Card.Img
                style={qrCodeStyle}
                src="/images/xiaohongshu.jpg"
                alt="Redbook QR Code"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Card.Title className="card-title-top">Instagram</Card.Title>
              <Card.Img
                style={qrCodeStyle}
                src="/images/insta.jpg"
                alt="Instagram QR Code"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Card.Title className="card-title-top">微信客服</Card.Title>
              <Card.Img
                style={qrCodeStyle}
                src="/images/wechat.jpg"
                alt="Wechat QR Code"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            诚招合作伙伴，合作共赢。另长期招聘 IT / Marketing 实习生
            (可加Unimelb internship program合作)
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
