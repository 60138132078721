import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Register.css";

export const schools = [
  "The University of Melbourne",
  "The University of Sydney",
  "Monash Univeristy",
  "University of New South Wales",
];

export const studies = [
  "Bachelor of Commerce",
  "Bachelor of Science",
  "Master of Computer Science",
  "Master of Data Science",
  "Master of Information System",
  "Master of Information Technology",
  "Master of Software Engineering",
];

const Register = () => {
  // ***SWITCH TESTING MODE & PRODUCTION MODE***
  // const local_register = "http://localhost:1235";
  const remote_register =
    "https://victor-edu-website-backend-a3f8287f423c.herokuapp.com";

  const [formData, setFormData] = useState({
    nickname: "",
    email: "",
    school: "",
    study: "",
    phone: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [err, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.post(`${remote_register}/api/auth/register`, formData);
      navigate("/login");
    } catch (err) {
      setError(err.response.data);
    }
  };

  return (
    <div className="register-container">
      <form onSubmit={handleSubmit} className="register-form">
        <div className="login-header">
          <Link to="/" className="auth-logo">
            <img
              className="logo"
              src="/images/victor-logo.svg"
              alt="Victor Logo"
            />
            <p>Victor Education</p>
          </Link>
        </div>
        <div className="input-group">
          <label>
            <select
              name="school"
              aria-label="University"
              onChange={handleInputChange}
              required
            >
              <option value="">-- 请选择您的学校 --</option>
              {schools.map((school, index) => (
                <option key={index} value={school}>
                  {school}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="input-group">
          <label>
            <select
              name="study"
              aria-label="Study"
              onChange={handleInputChange}
              required
            >
              <option value="">-- 请选择您就读的专业 --</option>
              {studies.map((study, index) => (
                <option key={index} value={study}>
                  {study}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="input-group">
          <input
            type="tel"
            name="phone"
            aria-label="Phone number (optional)"
            placeholder="移动电话 (选填)"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group">
          <input
            type="text"
            name="nickname"
            aria-label="Nickname"
            placeholder="昵称 *"
            value={formData.nickname}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="input-group">
          <input
            type="email"
            name="email"
            aria-label="Email"
            placeholder="电子邮箱 *"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="input-group">
          <input
            type="password"
            name="password"
            aria-label="Password"
            placeholder="密码 *"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="register-button">
          注册账号
        </button>

        {err && <p className="err">{err.message}</p>}

        <div className="register-actions">
          <Link to="/login" className="register-link">
            已有账号？登陆页面
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Register;
