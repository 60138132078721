import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Single.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";

function Single() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  // ***SWITCH TESTING MODE & PRODUCTION MODE***
  // const local_url = "http://localhost:1235";
  const remote_url =
    "https://victor-edu-website-backend-a3f8287f423c.herokuapp.com";

  useEffect(() => {
    const getCourse = async () => {
      try {
        const res = await axios.get(`${remote_url}/api/courses/${path}`, {
          withCredentials: true,
        });
        setData(res.data);
      } catch (error) {
        // Handle 403 Forbidden error specifically, or set a general error message
        if (error.response && error.response.status === 403) {
          setError("抱歉，您的访问无效。请重新尝试。");
        } else {
          setError("An unexpected error occurred.");
        }
        console.error("Error fetching subscription data", error);
      }
    };

    getCourse();
  }, [path]);

  return (
    <Container className="outlet">
      {error ? (
        <div
          style={{
            color: "#d0342c",
            fontSize: "1.25rem",
            fontWeight: "500",
            padding: "1rem",
          }}
          className="error-message"
        >
          <span>&#9888;</span> {error}
        </div>
      ) : (
        <>
          <div className="single-title">
            <h1 style={{ textTransform: "uppercase" }}>
              {data.code} {data.name}
            </h1>
            <h3>
              {data.type} {data.tutor}
            </h3>
          </div>

          <div className="video-container">
            <iframe
              src={
                data.url
                  ? data.url
                  : "https://www.youtube.com/embed/z2STrELVids?si=WnraCwbLvu2yLMBw"
              }
              title="YouTube video"
              allowFullScreen
            ></iframe>
          </div>
        </>
      )}
      ;
    </Container>
  );
}

export default Single;
