import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthContexProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    try {
      const item = localStorage.getItem("user");
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error("Error reading 'user' from localStorage:", error);
      return null;
    }
  });

  // ***SWITCH TESTING MODE & PRODUCTION MODE***
  // const local_auth = "http://localhost:1235";
  const remote_auth =
    "https://victor-edu-website-backend-a3f8287f423c.herokuapp.com";

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  const login = async (inputs) => {
    try {
      const response = await fetch(`${remote_auth}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputs),
        credentials: "include",
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Unknown error occurred");
      }

      const data = await response.json();
      setCurrentUser(data);
      return { success: true, user: data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  };

  const logout0 = async (inputs) => {
    console.log("User are logging out...");
    await axios.post(`${remote_auth}/api/auth/logout`, {
      withCredentials: true,
    });
    setCurrentUser(null);
  };

  const logout = async () => {
    try {
      const response = await fetch(`${remote_auth}/api/auth/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        // Using debugger to pause execution
        // debugger;

        setCurrentUser(null);
        localStorage.removeItem("user");

        // Write logs to local storage
        localStorage.setItem("logoutData", JSON.stringify(data));
      } else {
        console.log("Logout failed:", response.status, response.statusText);

        // Write error to local storage
        localStorage.setItem(
          "logoutError",
          `Logout failed: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Logout error:", error);
      localStorage.setItem("logoutException", `Logout error: ${error}`);
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
