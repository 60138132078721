import React, { useState, useContext } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

function Login() {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [err, setError] = useState(null);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleLogin1 = async (e) => {
    e.preventDefault();
    try {
      console.log("*** USER INPUTS *** \n", inputs);
      await login(inputs);
      navigate("/");
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setError(err.response.data);
      } else {
        setError("An unknown error occurred");
      }
    }
  };

  // TEST at 11:57 PM, 11 Feb ***
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await login(inputs);

      if (result && result.success) {
        navigate("/");
      } else if (result && !result.success) {
        console.log("*** ERROR ***", result.message);
        setError(result.message);
      }
    } catch (err) {
      setError("An unknown error occurred");
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <div className="login-header">
          <Link to="/" className="auth-logo">
            <img
              className="logo"
              src="/images/victor-logo.svg"
              alt="Victor Logo"
            />
            <p>Victor Education</p>
          </Link>
        </div>

        <div className="input-group">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="电子邮箱"
            aria-label="Email"
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-group">
          <input
            type="password"
            id="password"
            name="password"
            placeholder="密码"
            aria-label="Password"
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" className="login-button">
          登入
        </button>

        {err && <p className="err">{err}</p>}

        <div className="login-actions">
          <Link to="/forgot-password" className="login-link">
            忘记密码?
          </Link>
          <Link to="/register" className="login-link">
            暂无账号? 注册账号
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Login;
