import React, { useEffect, useState } from "react";
import "./Gallery.css";
import { Link } from "react-router-dom";

function Gallery({ title, images }) {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [itemsPerScreen, setItemsPerScreen] = useState(5);

  useEffect(() => {
    const updateItemsPerScreen = () => {
      if (window.innerWidth <= 600) {
        setItemsPerScreen(2);
      } else if (window.innerWidth <= 992) {
        setItemsPerScreen(3);
      } else if (window.innerWidth <= 1400) {
        setItemsPerScreen(4);
      } else {
        setItemsPerScreen(5);
      }
    };

    // Set the initial value on mount
    updateItemsPerScreen();
    // Add event listener for window resize
    window.addEventListener("resize", updateItemsPerScreen);
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", updateItemsPerScreen);
  }, []);

  // This will give you the total number of slides
  //   const slidesCount = Math.ceil(images.length / itemsPerScreen);
  const slidesCount = Math.ceil(images.length);

  const handleControlClick = (direction) => {
    if (direction === "left") {
      setSliderIndex((prevIndex) =>
        prevIndex > 0
          ? prevIndex - itemsPerScreen
          : slidesCount - itemsPerScreen
      );
    } else if (direction === "right") {
      setSliderIndex((prevIndex) =>
        prevIndex < slidesCount - itemsPerScreen
          ? prevIndex + itemsPerScreen
          : 0
      );
    }
  };

  const sliderStyle = {
    transform: `translateX(-${sliderIndex * (100 / itemsPerScreen)}%)`,
  };

  // Determine if the right button should be disabled
  // const isRightButtonDisabled = sliderIndex >= slidesCount - 1;
  const isRightButtonDisabled = sliderIndex >= slidesCount - itemsPerScreen;

  return (
    <div className="row-container">
      <div className="header">
        <h4 className="row-title">{title}</h4>
        {/* <div class="progress-bar">Full List</div> */}
      </div>
      <div className="carousel-container">
        <button
          className="handle left-handle"
          onClick={() => handleControlClick("left")}
          disabled={sliderIndex === 0}
        >
          <div className="text">&#8249;</div>
        </button>

        <div className="slider" style={sliderStyle}>
          {images.map((post, index) => (
            <Link
              key={post.slug}
              to={{
                pathname: `/item/${post.slug}`,
              }}
              className="card-link"
            >
              <img src={post.img} alt={post.slug} className="card-img" />
            </Link>
          ))}
        </div>

        <button
          className="handle right-handle"
          onClick={() => handleControlClick("right")}
          disabled={isRightButtonDisabled}
        >
          <div className="text">&#8250;</div>
        </button>
      </div>
    </div>
  );
}

export default Gallery;
